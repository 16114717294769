import RandomUtils from "./random-utils";

enum ExtensionByMime {
	"image/apng" = ".apng",
	"image/avif" = ".vif",
	"image/gif" = ".gif",
	"image/jpeg" = ".jpg",
	"image/png" = ".png",
	"image/svg+xml" = ".svg",
	"image/webp" = ".webp",
}

export default class FileUtils {

	static getObjectURL(file: File | Blob | null): string {
		if (!file) {
			return "";
		}

		return URL.createObjectURL(file);
	}

	// 	static async downloadBlob(url: string): Promise<Blob> {
	// 		const response = await axios.get(url, { responseType: 'blob' });
	//
	// 		return response.data;
	// 	}

	private static getExtensionByMime(mime: keyof typeof ExtensionByMime) {
		return ExtensionByMime[mime];
	}

	static getRandomFileName(mime: string) {
		if (mime in ExtensionByMime) {
			return "foto_" + RandomUtils.getUUID() + this.getExtensionByMime(mime as keyof typeof ExtensionByMime);
		}

		return "";
	}


	static getBlob(dataURL: string) {
		const splittedDataURL = dataURL.split(",");

		const mimeIsMatching = splittedDataURL[0].match(/:(.*?);/);

		if (!mimeIsMatching) {
			return null;
		}

		const mime = mimeIsMatching[1];

		const fileASCIIEncode = atob(splittedDataURL[1]);

		const fileASCIIEncodeLength = fileASCIIEncode.length;
		const unsignedInt8Bit = new Uint8Array(fileASCIIEncodeLength);

		for (let i = 0; i < fileASCIIEncodeLength; i++) {
			unsignedInt8Bit[i] = fileASCIIEncode.charCodeAt(i);
		}

		return new Blob([unsignedInt8Bit], {
			type: mime,
		});
	}
}