export default class Camera {
	private cameraElement;
	private canvasElement;

	constructor(cameraElement: HTMLVideoElement, canvasElement: HTMLCanvasElement) {
		this.cameraElement = cameraElement
		this.canvasElement = canvasElement
	}

	adjustVideoSize(width: number, height: number) {
		const aspectRatio = width / height

		if (width >= height) {
			this.cameraElement.width = aspectRatio * this.cameraElement.height
		} else {
			this.cameraElement.height = this.cameraElement.width / aspectRatio
		}
	}

	async setup(): Promise<void> {
		if (navigator.mediaDevices === undefined) {
			throw new Error("Navigator MediaDevices is not available in not secure context")
		}

		if (navigator.mediaDevices.getUserMedia === undefined) {
			throw new Error("Navigator MediaDevices getUserMedia is not available")
		}

		const mediaStream = await navigator.mediaDevices.getUserMedia({
			audio: false,
			video: {
				facingMode: "environment",
				width: { ideal: 720 },
			}
		})

		if ("srcObject" in this.cameraElement) {
			this.cameraElement.srcObject = mediaStream
		}

		this.cameraElement.addEventListener("loadeddata", async () => {
			this.adjustVideoSize(this.cameraElement.videoWidth, this.cameraElement.videoHeight)
		}, false)
	}

	_drawImage() {
		const imageWidth = this.cameraElement.videoWidth
		const imageHeight = this.cameraElement.videoHeight

		const canvasContext = this.canvasElement.getContext("2d") as CanvasRenderingContext2D;

		this.canvasElement.width = imageWidth
		this.canvasElement.height = imageHeight

		canvasContext.drawImage(this.cameraElement, 0, 0, imageWidth, imageHeight)

		return { imageWidth, imageHeight }
	}

	takeBlobPhoto({ type, quality } = { type: "png", quality: 1 }): Promise<Blob | null> {
		const { imageWidth, imageHeight } = this._drawImage()

		return new Promise((resolve, reject) => {
			if (imageWidth === 0 && imageHeight == 0) {
				return reject(null)
			}

			this.canvasElement.toBlob((blob) => {
				if (blob === null) {
					return reject(null);
				}

				return resolve(blob)
			}, type, quality)
		})
	}

	takeBase64Photo({ type, quality } = { type: "png", quality: 1 }) {
		const { imageWidth, imageHeight } = this._drawImage()
		const base64 = this.canvasElement.toDataURL("image/" + type, quality)

		return { base64, imageWidth, imageHeight }
	}

	stopCamera() {
		if (!this.cameraElement.srcObject) {
			return;
		}

		const cameraElementSrcObject = this.cameraElement.srcObject

		if ("getVideoTracks" in cameraElementSrcObject) {
			cameraElementSrcObject.getVideoTracks().forEach(track => track.stop())
		}
	}
}
