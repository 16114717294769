<template>
<div id="home-view" class="container">
	<!-- <BackButton></BackButton> -->
	<div class="mb-3">
		<h1 class="my-0">
			Home
		</h1>
	</div>
	<div class="d-grid">
		<router-link :to="{ name: 'photos.create' }" class="btn btn-info mb-3">
			Scatta foto
		</router-link>

		<router-link :to="{ name: 'data-sync.index' }" class="btn btn-primary mb-3">
			Sincronizza
		</router-link>

		<button class="btn btn-danger mb-3" type="button" @click="performLogout">
			Logout
		</button>
	</div>
</div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import localForage from 'localforage';

@Component
export default class HomeView extends Vue {

	async performLogout() {
		await localForage.removeItem("USER");
		await this.$router.replace({ name: "auth.login" });
	}
}
</script>