<template>
<div class="container mb-4">
	<h4>{{ label }}</h4>

	<div class="form-check" v-for="tag in tags" :key="tag">
		<input class="form-check-input tags-checkboxes" :id="getValue(tag)" type="checkbox" :value="tag"
			:data-short-name="getDataShortName(tag)" :data-long-name="tag">
		<label class="form-check-label" :for="getValue(tag)">
			{{ getValue(tag) }}
		</label>
	</div>
</div>
</template>


<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'

import settings from '@/settings';

@Component
export default class TagsCheckboxes extends Vue {
	@Prop(Array) tags!: string[];
	label = settings.tagsLabel

	getValue(tag: string) {
		return tag.split("#")[1] || "";
	}

	getDataShortName(tag: string) {
		return tag.split("#")[0] || "";
	}
}
</script>