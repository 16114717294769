<template>
<div id="data-sync-view" class="container">
	<div class="mb-3" style="display: flex; flex-direction: row; align-items: center;">
		<h1 class="my-0">
			Sincronizzazione dati
		</h1>
	</div>
	<div v-if="loading">
		<div class="card mb-3">
			<div class="card-body">
				Caricamento in corso...

				<div class="progress mt-1">
					<div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
						aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%;"></div>
				</div>
			</div>
		</div>
	</div>
	<div v-else>
		<div class="mb-3">
			<button type="button" class="btn btn-primary w-100" @click="syncLocalDataToRemote()">
				Sincronizza dati
			</button>
		</div>

		<div class="card border-danger mb-3">
			<div class="card-body">
				<h4 class="card-title text-red">
					Elimina dati locali
				</h4>

				<p class="card-text">
					Attenzione, questa azione non può essere annullata!<br>
					Tutti i dati salvati sul dispositivo, <strong>sincronizzati e non</strong>, verranno eliminati.
				</p>

				<button type="button" class="btn btn-danger w-100" @click="deleteLocalData()">
					Elimina dati locali
				</button>
			</div>
		</div>
	</div>
</div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import ConstructionSitesRepository from '@/repositories/construction-sites-repository';
import OrdersRepository from '@/repositories/orders-repository';
import FieldOneRepository from '@/repositories/field-one-repository';
import FieldTwoRepository from '@/repositories/field-two-repository';
import FieldThreeRepository from '@/repositories/field-three-repository';
import PhotographsRepository from '@/repositories/photographs-repository';
import Photograph from '@/interfaces/Photograph';
import FieldOne from '@/interfaces/FieldOne';
import FieldTwo from '@/interfaces/FieldTwo';
import FieldThree from '@/interfaces/FieldThree';

@Component
export default class DataSyncView extends Vue {
	loading = false;

	photographs: Map<string, Photograph[]> = new Map();
	fieldOneValues: Map<number, FieldOne[]> = new Map();
	fieldTwoValues: Map<string, FieldTwo[]> = new Map();
	fieldThreeValues: Map<string, FieldThree[]> = new Map();

	get isOnline() {
		return navigator.onLine;
	}

	get isOffline() {
		return !navigator.onLine;
	}

	get canSyncPhotographs() {
		for (const currentPhotographs of this.photographs.values()) {
			const foundCurrentPhotograph = currentPhotographs.find(currentPhotograph => currentPhotograph.id === null);

			if (foundCurrentPhotograph) {
				return true;
			}
		}

		return false;
	}

	get canSyncFieldOneValues() {
		for (const currentFieldOneValues of this.fieldOneValues.values()) {
			const foundFieldOne = currentFieldOneValues.find(fieldOne => fieldOne.id === null);

			if (foundFieldOne) {
				return true;
			}
		}

		return false;
	}

	get canSyncFieldTwoValues() {
		for (const currentFieldTwoValues of this.fieldTwoValues.values()) {
			const foundFieldTwo = currentFieldTwoValues.find(fieldTwo => fieldTwo.id === null);

			if (foundFieldTwo) {
				return true;
			}
		}

		return false;
	}

	get canSyncFieldThreeValues() {
		for (const currentFieldThreeValues of this.fieldThreeValues.values()) {
			const foundFieldThree = currentFieldThreeValues.find(fieldThree => fieldThree.id === null);

			if (foundFieldThree) {
				return true;
			}
		}

		return false;
	}

	get noDataToBeSync() {
		return !(this.canSyncPhotographs
			|| this.canSyncFieldOneValues
			|| this.canSyncFieldTwoValues
			|| this.canSyncFieldThreeValues);
	}

	syncDataDisabled() {
		return this.isOffline;
	}

	async mounted() {
		this.loading = true;

		this.photographs = await PhotographsRepository.getAll();
		this.fieldOneValues = await FieldOneRepository.getAll();
		this.fieldTwoValues = await FieldTwoRepository.getAll();
		this.fieldThreeValues = await FieldThreeRepository.getAll();

		this.loading = false;
	}

	async downloadData() {
		this.loading = true;

		await OrdersRepository.replaceAllFromRemote();
		await ConstructionSitesRepository.replaceAllFromRemote();
		await FieldOneRepository.replaceAllFromRemote();
		await FieldTwoRepository.replaceAllFromRemote();
		await FieldThreeRepository.replaceAllFromRemote();

		this.loading = false
	}

	async deleteLocalData() {
		const confirmation = confirm("Per procedere è richiesta una conferma. Continuare?")

		if (!confirmation) {
			return
		}

		this.loading = true

		Promise.all([
			OrdersRepository.deleteAll(),
			ConstructionSitesRepository.deleteAll(),
			FieldOneRepository.deleteAll(),
			FieldTwoRepository.deleteAll(),
			FieldThreeRepository.deleteAll(),
			PhotographsRepository.deleteAll(),
		]).finally(() => this.loading = false);

		this.photographs = await PhotographsRepository.getAll();
		this.fieldOneValues = await FieldOneRepository.getAll();
		this.fieldTwoValues = await FieldTwoRepository.getAll();
		this.fieldThreeValues = await FieldThreeRepository.getAll();
	}

	async syncLocalDataToRemote() {
		this.loading = true;

		try {
			await FieldOneRepository.syncAll();
			await FieldTwoRepository.syncAll();
			await FieldThreeRepository.syncAll();
			await PhotographsRepository.syncLocalAll();

			await OrdersRepository.replaceAllFromRemote();
			await ConstructionSitesRepository.replaceAllFromRemote();
			await FieldOneRepository.replaceAllFromRemote();
			await FieldTwoRepository.replaceAllFromRemote();
			await FieldThreeRepository.replaceAllFromRemote();

			this.photographs      = await PhotographsRepository.getAll();
			this.fieldOneValues   = await FieldOneRepository.getAll();
			this.fieldTwoValues   = await FieldTwoRepository.getAll();
			this.fieldThreeValues = await FieldThreeRepository.getAll();
		} catch (error) {
			console.error(error);
		} finally {
			this.loading = false
		}
	}
}
</script>