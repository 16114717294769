<template>
	<div id="camera-preview" class="mb-4">
		<video autoPlay playsInline muted id="camera"></video>
		<div class="d-grid gap-2" id="camera-controls">
			<button class="btn btn-secondary" v-if="showCameraButton" type="button" @click="takePicture()">
				<i class="fa fa-camera"></i>
			</button>

			<button type="button" class="btn btn-primary" @click="dontShowCamera()">
				Indietro
			</button>
		</div>
	</div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import Camera from '@/utils/camera'

@Component
export default class CameraPreview extends Vue {
	camera: Camera | null = null;
	canvasElement!: HTMLCanvasElement;
	cameraElement: HTMLVideoElement | null = null;
	showCameraButton: true | false = false;

	async mounted() {
		this.canvasElement = document.createElement("canvas");
		this.cameraElement = document.getElementById("camera") as HTMLVideoElement | null;

		if (this.cameraElement === null) {
			return;
		}

		this.camera = new Camera(this.cameraElement, this.canvasElement);

		try {
			await this.camera.setup();
		} catch (error) {
			alert("Non è stato possibile inizializzare la fotocamera");
			console.error(error);
		}
	}

	async takePicture() {
		const blobPhoto = await this.camera?.takeBlobPhoto();

		if (blobPhoto == null) {
			alert("C'è stato qualche problema nel scattare la foto");
			return;
		}
		this.$emit("pushPhototraph", blobPhoto);

		this.dontShowCamera();
		this.camera?.stopCamera();
	}

	dontShowCamera() {
		this.camera?.stopCamera();

		this.$emit("showCamera", false);
	}

	beforeDestroy() {
		this.camera?.stopCamera();
	}
}
</script>

<style>
#camera {
	height: 100%;
	width: 100%;
}

#camera-preview {
	height: 60vh;
	width: 100%;
}
</style>