import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import MainHeader from '@/components/MainHeader.vue'
import localForage from 'localforage'
import { extendPrototype } from 'localforage-startswith'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import axios from 'axios'
import settings from './settings'
import UsersRepository from './repositories/users-repository'


Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

Vue.component("MainHeader", MainHeader);

axios.defaults.baseURL = settings.baseUrl + "/endpoint";

axios.interceptors.request.use(
	async request => {
		const user = await UsersRepository.getCurrentUser();

		request.headers["db_key"] = user?.db_key || "";
		request.headers["ID_utente"] = user?.id || "";

		return Promise.resolve(request);
	}, error => Promise.resolve(error)
);

// Add a response interceptor to handle the common errors
axios.interceptors.response.use(response => response,
	error => {
		let message = "";

		if (error.code == "ERR_NETWORK") { // We are offline
			message = "Si è verificato un errore durante la richiesta; il dispositivo potrebbe essere offline.";
		} else if (error.response.data.errors) {
			message = error.response.data.errors.join("\n");
		} else {
			message = "Si è verificato un errore durante la richiesta."
		}

		alert(message);

		return Promise.reject(error)
	}
);

// Configure our database name
localForage.config({
	name: "fototouch_pwa",
});

// Add startsWith to localForage
extendPrototype(localForage)

new Vue({
	router,
	render: h => h(App)
}).$mount('#app')
