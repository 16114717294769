import { User, RawUser } from "@/interfaces/User";
import axios from "axios";
import localforage from "localforage";

export default class UsersRepository {
	static namespace = "USER";

	static async login(username: string, password: string) {
		const formData = new FormData();

		formData.append("username", username)
		formData.append("password", password)

		const response = axios.post("/login.php", formData, {
			headers: { "Content-Type": "application/x-www-form-urlencoded" }
		}).catch(error => {
			console.error(error);
			throw new Error("C'è stato un errore nel processo di login");
		});

		const userValues: RawUser[] = (await response).data;
		const rawUser = userValues[0];

		if (rawUser == undefined) {
			throw new Error("C'è stato un errore nel processo di login");
		}

		const user: User = {
			id: rawUser.ID_utente || "",
			db_key: rawUser.db_key || "",
			type: rawUser.tipologia || null,
			error: rawUser["errore="] === undefined ? "error@nouser" : rawUser["errore="],
			token: rawUser.token || 0,
		};

		if (user.error == "") {
			await localforage.setItem(this.namespace, user);
		} else if (user.error == "error@nouser") {
			throw new Error("Non è stato trovato nessun utente");
		} else {
			throw new Error(user.error);
		}
	}

	static async getCurrentUser(): Promise<User | null> {
		return (await localforage.getItem(this.namespace)) || null;
	}
}