import Order from "@/interfaces/Order";
import axios from "axios";
import localforage from "localforage";

type RawOrder = {
	ID: string;
	commessa: string;
}

export default class OrdersRepository {
	static namespace = "ORDERS";

	static async getAll(): Promise<Order[]> {
		return (await localforage.getItem(this.namespace)) || [];
	}

	static async replaceAllFromRemote() {
		const response = await axios.get("/commesse.php");

		const data: RawOrder[] = response.data;
		const orders: Order[] = data.map(rawOrder => { return { id: parseInt(rawOrder.ID), name: rawOrder.commessa } });

		await localforage.setItem(this.namespace, orders);
	}

	static async find(orderId: number): Promise<Order | null> {
		return (await this.getAll()).find(order => orderId == order.id) || null;
	}

	static async deleteAll() {
		await localforage.setItem(this.namespace, []);
	}
}