<template>
	<header>
		<nav class="navbar navbar-expand-lg mb-3" :class="{
			'bg-warning navbar-dark': isDevelopmentEnvironment,
			'bg-light navbar-light': isProductionEnvironment,
		}">
			<div class="container">
				<router-link to="/" class="navbar-brand">
					<img src="img/icons/icon.png" height="50px"> Home
				</router-link>
				<ul class="navbar-nav">
					<li class="nav-item" v-if="isDevelopmentEnvironment">
						<a href="#" class="nav-link text-white">
							DEVELOPMENT
						</a>
					</li>
					<li class="nav-item" v-if="isOffline">
						<a href="#" class="nav-link text-yellow">
							&#9888;
							Sei offline
						</a>
					</li>
				</ul>
			</div>
		</nav>
	</header>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class MainHeader extends Vue {
	isOnline = navigator.onLine;

	mounted() {
		window.addEventListener("online", () => this.isOnline = true);
		window.addEventListener("offline", () => this.isOnline = false);
	}

	get isOffline() {
		return !this.isOnline;
	}

	get isProductionEnvironment() {
		return location.hostname === "pwa.fototouch.it";
	}

	get isDevelopmentEnvironment() {
		return !this.isProductionEnvironment;
	}
}
</script>
