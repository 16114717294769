export default {
	baseUrl: "https://building.fototouch.it",
	hostname: "pwa.fototouch.it",

	fieldOrderLabel: "AREA-SETTORE-TIPOLOGIA",
	fieldConstructionLabel: "COMUNE",
	fieldOneLabel: "IMPIANTO/VIA",
	fieldTwoLabel: "ATTIVITÀ",
	fieldThreeLabel: "SOTTOATTIVITÀ",
	tagsLabel: "Tipologie",
};