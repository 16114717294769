import ConstructionSite from "@/interfaces/ConstructionSite";
import axios from "axios";
import localforage from "localforage";

type RawConstructionSite = {
	ID: string;
	ID_commessa: string;
	cantiere: string;
	lista_tags: string;
}

export default class ConstructionSitesRepository {
	static namespace = "CONSTRUCTION_SITES";

	static async getAll(): Promise<Map<number, ConstructionSite[]>> {
		const constructionSites: [] | null | undefined = await localforage.getItem(this.namespace);

		return new Map(constructionSites);
	}

	static async replaceAllFromRemote() {
		const response = await axios.get("/cantieri.php");

		const data: RawConstructionSite[] = response.data;

		const constructionSitesMap: Map<number, ConstructionSite[]> = new Map();

		for (const rawConstructionSite of data) {
			const orderId = parseInt(rawConstructionSite.ID_commessa);

			const constructionSites = constructionSitesMap.get(orderId) || [];

			constructionSites.push({
				id: parseInt(rawConstructionSite.ID),
				order_id: orderId,
				name: rawConstructionSite.cantiere,
				tags: rawConstructionSite.lista_tags.split(","),
			});

			constructionSitesMap.set(orderId, constructionSites);
		}

		await localforage.setItem(this.namespace, [...constructionSitesMap]);
	}

	static async deleteAll() {
		await localforage.setItem(this.namespace, []);
	}

	static async find(orderId: number): Promise<ConstructionSite[]> {
		return (await this.getAll()).get(orderId) || [];
	}
}