import FieldThree from "@/interfaces/FieldThree";
import RandomUtils from "@/utils/random-utils";
import axios from "axios";
import localforage from "localforage";
import FieldTwoRepository from "./field-two-repository";

type RawFieldThree = {
	ID: string,
	ID_campo2: string,
	campo3: string,
}

export default class FieldThreeRepository {
	static namespace = "FIELD_THREE_VALUES";

	static async getAll(): Promise<Map<string, FieldThree[]>> {
		const fieldThreeValues: [] | null | undefined = await localforage.getItem(this.namespace);

		return new Map(fieldThreeValues);
	}

	static async syncAll() {
		const fieldThreeValuesMap = await this.getAll();

		const syncFieldThreeValusMap: Map<string, FieldThree[]> = new Map();

		for (const [key, fieldThreeValues] of fieldThreeValuesMap.entries()) {
			for (const fieldThree of fieldThreeValues) {
				if (fieldThree.id) continue;

				const params = new FormData();

				const fieldTwoId = fieldThree.field_two_id ? fieldThree.field_two_id.toString() : "";

				params.append("campo_3_nome", fieldThree.name)
				params.append("campo_2_id", fieldTwoId)

				try {
					const response = await axios.post("registra_campo3.php", params, {
						headers: { "Content-Type": "application/x-www-form-urlencoded" }
					})

					const fieldThreeId = response.data[0]?.campo_3_id || null;
					fieldThree.id = fieldThreeId;
				} catch (error) {
					console.error(error)
				}
			}

			syncFieldThreeValusMap.set(key, fieldThreeValues);
		}

		localforage.setItem(this.namespace, [...syncFieldThreeValusMap]);
	}

	static async replaceAllFromRemote() {
		const response = await axios.get("/campo3.php");

		const data: RawFieldThree[] = response.data;

		const fieldThreeMap: Map<string, FieldThree[]> = new Map();

		const currentFieldThreeMap = await this.getAll();

		for (const rawFieldThree of data) {
			const fieldTwoId = parseInt(rawFieldThree.ID_campo2);

			const fieldTwo = await FieldTwoRepository.findById(fieldTwoId);

			if (!fieldTwo) {
				console.error(`Dont find ${fieldTwoId} in FieldThreeRepository.replaceAllFromRemote`)
				continue;
			}

			const fieldThreeValues = fieldThreeMap.get(fieldTwo.uuid) || [];
			const currentFieldThreeValues = currentFieldThreeMap.get(fieldTwo.uuid) || [];

			const fieldThreeId = parseInt(rawFieldThree.ID);
			const currentFieldThree = currentFieldThreeValues.find(currentFieldThree => currentFieldThree.id == fieldThreeId);

			fieldThreeValues.push({
				uuid: currentFieldThree ? currentFieldThree.uuid : RandomUtils.getUUID(),
				id: fieldThreeId,
				name: rawFieldThree.campo3,
				field_two_id: fieldTwoId,
			});

			fieldThreeMap.set(fieldTwo.uuid, fieldThreeValues);
		}

		await localforage.setItem(this.namespace, [...fieldThreeMap]);
	}

	static async find(fieldTwoUuid: string): Promise<FieldThree[]> {
		return (await this.getAll()).get(fieldTwoUuid) || [];
	}

	static async deleteAll() {
		localforage.setItem(this.namespace, []);
	}

	static async create(fieldTwoUuid: string, fieldThreeValue: string) {
		const fieldThreeValues = await this.find(fieldTwoUuid);

		const fieldTwo = await FieldTwoRepository.findByUuid(fieldTwoUuid);

		if (!fieldTwo) {
			return null;
		}

		const fieldThree: FieldThree = {
			uuid: RandomUtils.getUUID(),
			id: null,
			name: fieldThreeValue,
			field_two_id: fieldTwo.id,
		};

		fieldThreeValues.push(fieldThree);

		const fieldThreeMap = (await this.getAll()).set(fieldTwoUuid, fieldThreeValues)
		localforage.setItem(this.namespace, [...fieldThreeMap]);

		return fieldThree;
	}

	static async update(fieldTwoUuid: string, fieldThreeValues: FieldThree[]) {
		const fieldsThreeMap = await this.getAll();

		fieldsThreeMap.set(fieldTwoUuid, fieldThreeValues);

		await localforage.setItem(this.namespace, [...fieldsThreeMap]);
	}

	static async findByUuid(fieldThreeUuid: string) {
		const fieldThreeMap = await this.getAll();

		for (const fieldTwoValues of fieldThreeMap.values()) {
			const fieldThree = fieldTwoValues.find(fieldThree => fieldThree.uuid == fieldThreeUuid);

			if (fieldThree) {
				return fieldThree;
			}
		}

		return null;
	}
}