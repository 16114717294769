import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import LoginView from '@/views/LoginView.vue'
import HomeView from '@/views/HomeView.vue'
import DataSyncView from '@/views/DataSyncView.vue'
import PhotosCreateView from '@/views/PhotosCreateView.vue'
import authMiddleware from '@/middlewares/auth-middleware'
import guestMiddleware from '@/middlewares/guest-middleware'

Vue.use(VueRouter)

const routes: RouteConfig[] = [
	{
		path: '/',
		name: 'home.index',
		component: HomeView,
		beforeEnter: authMiddleware,
	},
	{
		path: '/login',
		name: 'auth.login',
		component: LoginView,
		beforeEnter: guestMiddleware,
	},
	{
		path: '/photos/create',
		name: 'photos.create',
		component: PhotosCreateView,
		beforeEnter: authMiddleware,
	},
	{
		path: '/data-sync',
		name: 'data-sync.index',
		component: DataSyncView,
		beforeEnter: authMiddleware,
	},
]

const router = new VueRouter({
	base: process.env.BASE_URL,
	routes
})

export default router
