<template>
<div class="mt-3">
	<div class="mb-3">
		<button type="button" class="btn btn-primary btn-sm" @click="openModal">
			Crea {{ label }}
		</button>
	</div>

	<b-modal id="create-field-three-modal" header-close-variant=" btn btn-light" cancel-title="Chiudi" @ok="create">
		<template #modal-title>
			<h5 class="modal-title">Crea {{ label }}</h5>
		</template>

		<div class="input-group mb-3">
			<span class="input-group-text">{{ label }}</span>
			<input type="text" class="form-control" id="field-three-input">
		</div>
	</b-modal>
</div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import settings from '@/settings';
import FieldTwo from '@/interfaces/FieldTwo';
import FieldThreeRepository from '@/repositories/field-three-repository';

@Component
export default class CreateFieldThree extends Vue {
	label = settings.fieldThreeLabel;
	fieldTwoText: string = "";
	@Prop() fieldTwo!: FieldTwo;

	openModal() {
		this.$bvModal.show('create-field-three-modal')
	}

	async create() {
		const fieldThreeInput = document.getElementById("field-three-input") as HTMLInputElement
		const fieldThreeValue = fieldThreeInput.value.trim();

		if (!fieldThreeValue) {
			alert("È obbligatorio inserire un valore nell'input");
			return;
		}

		const fieldThree = await FieldThreeRepository.create(this.fieldTwo.uuid, fieldThreeValue);

		this.$emit("createFieldThree", fieldThree);
	}
}
</script>