<template>
<div class="input-group mb-3">
	<span class="input-group-text">{{ label }}</span>
	<select name="field_three_id" class="form-control" id="field-three-select" @input="populateFieldFourValues()">
		<option value="">Seleziona</option>
		<option v-for="fieldThree in fieldThreeValues" :value="fieldThree.uuid" :key="fieldThree.uuid"
			:data-name="fieldThree.name">
			{{ fieldThree.name }}
		</option>
	</select>
	<button class="btn btn-success" type="button" @click="openModal">
		<i class="fa fa-plus"></i>
	</button>

	<b-modal id="create-field-three-modal" header-close-variant=" btn btn-light" cancel-title="Chiudi" @ok="create">
		<template #modal-title>
			<h5 class="modal-title">Crea {{ label }}</h5>
		</template>

		<div class="input-group mb-3">
			<span class="input-group-text">{{ label }}</span>
			<input type="text" class="form-control" id="field-three-input">
		</div>
	</b-modal>
</div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import FieldThree from '@/interfaces/FieldThree'
import settings from '@/settings';
import FieldThreeRepository from '@/repositories/field-three-repository';
import FieldTwo from '@/interfaces/FieldTwo';

@Component
export default class FieldThreeSelect extends Vue {
	label = settings.fieldThreeLabel;
	@Prop(Array) fieldThreeValues!: FieldThree[];
	@Prop(Object) fieldTwo!: FieldTwo;

	openModal() {
		this.$bvModal.show('create-field-three-modal')
	}

	populateFieldFourValues() { //TODO future
		const fieldThreeSelect = document.getElementById("field-three-select") as HTMLInputElement
		const fieldThreeValue = fieldThreeSelect.value.trim();

		if (fieldThreeValue) {
			this.$emit("populateFieldFourValues", true);
		}

		this.$emit("populateFieldFourValues", false);
	}

	async create() {
		const fieldThreeInput = document.getElementById("field-three-input") as HTMLInputElement
		const fieldThreeValue = fieldThreeInput.value.trim();

		if (!fieldThreeValue) {
			alert("È obbligatorio inserire un valore nell'input");
			return;
		}

		const fieldThree = await FieldThreeRepository.create(this.fieldTwo.uuid, fieldThreeValue);

		this.$emit("createFieldThree", fieldThree);
	}
}
</script>