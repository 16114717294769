<template>
<div class="input-group mb-3">
	<span class="input-group-text">{{ label }}</span>
	<select name="construction_site_id" class="form-control" id="construction-sites-select"
		@input="populateFieldOneValues">
		<option value="">Seleziona</option>
		<option v-for="constructionSite in constructionSites" :value="constructionSite.id" :key="constructionSite.id">
			{{ constructionSite.name }}
		</option>
	</select>
</div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import settings from '@/settings';
import ConstructionSite from '@/interfaces/ConstructionSite';
import FieldOneRepository from '@/repositories/field-one-repository';

@Component
export default class ConstructionSitesSelect extends Vue {
	label = settings.fieldConstructionLabel;

	@Prop(Array) constructionSites!: ConstructionSite[];

	async populateFieldOneValues() {
		const constructionSitesSelect = document.getElementById("construction-sites-select") as HTMLSelectElement;

		const constructionSitesSelectValue = parseInt(constructionSitesSelect.value);

		const fieldOneValues = await FieldOneRepository.find(constructionSitesSelectValue);

		this.$emit("populateFieldOneValues", fieldOneValues)
	}
}
</script>
