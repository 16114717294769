<template>
<div class="input-group mb-3">
	<span class="input-group-text">{{ label }}</span>
	<select name="field_one_id" class="form-control" id="field-one-select" @input="populateFieldTwoValues()">
		<option value="">Seleziona</option>
		<option v-for="fieldOne in fieldOneValues" :value="fieldOne.uuid" :key="fieldOne.uuid"
			:data-name="fieldOne.name">
			{{ fieldOne.name }}
		</option>
	</select>
	<button class="btn btn-success" type="button" @click="openModal">
		<i class="fa fa-plus"></i>
	</button>

	<b-modal id="create-field-one-modal" header-close-variant=" btn btn-light" cancel-title="Chiudi" @ok="create">
		<template #modal-title>
			<h5 class="modal-title">Crea {{ label }}</h5>
		</template>

		<div class="input-group mb-3">
			<span class="input-group-text">{{ label }}</span>
			<input type="text" class="form-control" id="field-one-input">
		</div>
	</b-modal>
</div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import FieldOne from '@/interfaces/FieldOne'
import FieldTwoRepository from '@/repositories/field-two-repository';
import settings from '@/settings';
import FieldOneRepository from '@/repositories/field-one-repository';
import ConstructionSite from '@/interfaces/ConstructionSite';

@Component
export default class FieldOneSelect extends Vue {
	label = settings.fieldOneLabel;
	@Prop(Array) fieldOneValues!: FieldOne[];
	@Prop(Object) constructionSite!: ConstructionSite;

	async populateFieldTwoValues() {
		const fieldOneValuesSelect = document.getElementById("field-one-select") as HTMLSelectElement;

		const fieldOneSelectValue = fieldOneValuesSelect.value;

		const fieldTwoValues = await FieldTwoRepository.find(fieldOneSelectValue);

		return this.$emit("populateFieldTwoValues", fieldTwoValues);
	}

	async create() {
		const fieldOneInput = document.getElementById("field-one-input") as HTMLInputElement
		const fieldOneValue = fieldOneInput.value.trim();

		if (!fieldOneValue) {
			alert("È obbligatorio inserire un valore nell'input");
			return;
		}

		const fieldOne = await FieldOneRepository.create(this.constructionSite.id, fieldOneValue);

		this.$emit("createFieldOne", fieldOne);
	}

	openModal() {
		this.$bvModal.show('create-field-one-modal')
	}
}
</script>