<template>
<div class="container">
	<div class="row">
		<div v-if="cameraVisible">
			<camera-preview @showCamera="showCamera" @pushPhototraph="pushPhototraph"></camera-preview>
		</div>

		<form v-show="cameraIsNotVisible" class="mb-2">
			<div class="mb-1">
				<h1>Scatta foto</h1>
			</div>

			<div class="row">
				<div class="col-12 text-center mb-3">
					<h4 :class="{
						'text-success': hasBeenGeolocatedLocation,
						'text-danger': !hasBeenGeolocatedLocation,
					}" :title="geolocationTitle">
						<i class="fa fa-globe"></i> {{ hasBeenGeolocatedLocation
							? "Geolocalizzazione acquisita"
							: "" }}
					</h4>
				</div>
			</div>

			<orders-select :orders="orders" @populateConstructionSites="populateConstructionSites"></orders-select>

			<construction-sites-select v-if="constructionSitesSelectVisible" :constructionSites="constructionSites"
				@populateFieldOneValues="populateFieldOneValues"></construction-sites-select>

			<field-one-select :fieldOneValues="fieldOneValues" v-if="fieldOneSelectVisible"
				:constructionSite="currentConstructionSite" @createFieldOne="pushFieldOneValues"
				@populateFieldTwoValues="populateFieldTwoValues"></field-one-select>

			<create-field-one v-if="createFieldOneVisible" :constructionSite="currentConstructionSite"
				@createFieldOne="pushFieldOneValues"></create-field-one>

			<field-two-select v-if="fieldTwoSelectVisible" :fieldOne="currentFieldOne" :fieldTwoValues="fieldTwoValues"
				@createFieldTwo="pushFieldTwoValues"
				@populateFieldThreeValues="populateFieldThreeValues"></field-two-select>

			<create-field-two v-if="createFieldTwoVisible" :fieldOne="currentFieldOne"
				@createFieldTwo="pushFieldTwoValues"></create-field-two>

			<field-three-select v-if="fieldThreeSelectVisible" @createFieldThree="pushFieldThreeValues"
				:fieldTwo="currentFieldTwo" :fieldThreeValues="fieldThreeValues"
				@populateFieldFourValues="populateFieldFourValues"></field-three-select>

			<create-field-three v-if="createFieldThreeVisible" :fieldTwo="currentFieldTwo"
				@createFieldThree="pushFieldThreeValues"></create-field-three>

			<tags-checkboxes v-if="tagsCheckboxesVisible" :tags="constructionSiteTags"></tags-checkboxes>

			<div class="d-grid mb-3" v-if="photographButtonVisible">
				<button type="button" class="btn btn-secondary" @click="showCamera(true)">
					<i class="fa fa-camera"></i>
				</button>
			</div>

			<div class="input-group mb-3">
				<span class="input-group-text">Note</span>
				<textarea name="notes" id="photograph-notes" rows="3" class="form-control"></textarea>
			</div>

			<photographs-grid :currentPhotographs="photographs"
				@updatePhotographs="updatePhotographs"></photographs-grid>
		</form>
	</div>
</div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import OrdersSelect from '@/components/OrdersSelect.vue';
import ConstructionSitesSelect from '@/components/ConstructionSitesSelect.vue';
import FieldOneSelect from "@/components/FieldOneSelect.vue";
import FieldTwoSelect from "@/components/FieldTwoSelect.vue";
import FieldThreeSelect from "@/components/FieldThreeSelect.vue";
import TagsCheckboxes from "@/components/TagsCheckboxes.vue";
import CameraPreview from "@/components/CameraPreview.vue";
import PhotographsGrid from "@/components/PhotographsGrid.vue";
import CreateFieldOne from "@/components/CreateFieldOne.vue";
import CreateFieldTwo from "@/components/CreateFieldTwo.vue";
import CreateFieldThree from "@/components/CreateFieldThree.vue";
import PhotographsRepository from '@/repositories/photographs-repository';
import UsersRepository from '@/repositories/users-repository';

import FieldOne from '@/interfaces/FieldOne'
import FieldTwo from "@/interfaces/FieldTwo";
import FieldThree from "@/interfaces/FieldThree";

import ConstructionSite from '@/interfaces/ConstructionSite';
import Photograph from '@/interfaces/Photograph';
import OrdersRepository from '@/repositories/orders-repository';
import Order from '@/interfaces/Order';
import RandomUtils from '@/utils/random-utils';
import { User } from '@/interfaces/User';

@Component({
	components: {
		OrdersSelect,
		ConstructionSitesSelect,
		FieldOneSelect,
		FieldTwoSelect,
		FieldThreeSelect,
		TagsCheckboxes,
		CameraPreview,
		PhotographsGrid,
		CreateFieldOne,
		CreateFieldTwo,
		CreateFieldThree
	},
})
export default class PhotosCreateView extends Vue {
	orders: Order[] = [];
	constructionSites: ConstructionSite[] = [];
	fieldOneValues: FieldOne[] = [];
	fieldTwoValues: FieldTwo[] = [];
	fieldThreeValues: FieldThree[] = [];
	constructionSiteTags: string[] = [];

	photographs: Photograph[] = [];
	cameraVisible: true | false = false;

	longitude: number | null = null;
	latitude: number | null = null;
	accuracy: number | null = null;
	positionWatchId: number | null = null;

	currentConstructionSite: ConstructionSite | null = null;
	currentFieldOne: FieldOne | null = null;
	currentFieldTwo: FieldTwo | null = null;

	// --------------------------------------------------
	// Computed
	// --------------------------------------------------

	get hasBeenGeolocatedLocation() {
		return this.longitude != null || this.latitude != null || this.accuracy != null;
	}

	get geolocationTitle() {
		if (!this.hasBeenGeolocatedLocation) {
			return "Geolocalizzazione non acquisita";
		}

		return `Latitudine: ${this.latitude} - Longitudine: ${this.longitude} - Precisione: ${this.accuracy}`;
	}

	get constructionSitesSelectVisible() {
		return this.constructionSites.length > 0;
	}

	get fieldOneSelectVisible() {
		this.currentConstructionSite = this.constructionSite() || null;

		return this.constructionSitesSelectVisible && this.fieldOneValues.length > 0;
	}

	get fieldTwoSelectVisible() {
		this.currentFieldOne = this.fieldOne();

		return this.fieldOneSelectVisible && this.fieldTwoValues.length > 0;
	}

	get fieldThreeSelectVisible() {
		this.currentFieldTwo = this.fieldTwo();

		return this.fieldTwoSelectVisible && this.fieldThreeValues.length > 0;
	}

	get tagsCheckboxesVisible() {
		return this.currentFieldOne != null;
	}

	get photographButtonVisible() {
		return this.tagsCheckboxesVisible;
	}

	get cameraIsNotVisible() {
		return !this.cameraVisible;
	}

	get createFieldOneVisible() {
		return this.fieldOneValues.length == 0 && this.currentConstructionSite != null;
	}

	get createFieldTwoVisible() {
		return this.fieldTwoValues.length == 0 && this.currentFieldOne != null;
	}

	get createFieldThreeVisible() {
		return this.fieldThreeValues.length == 0 && this.currentFieldTwo != null;
	}

	//--------------------------------------------------
	// Watch
	//--------------------------------------------------

	// 	@Watch("fieldOneValues")
	// 	onFieldOneValues(nextFieldOneValues: FieldOne[], prevFieldOneValues: FieldOne[]) {
	// 		console.log(nextFieldOneValues);
	// 		console.log(prevFieldOneValues);
	//
	// 		const fieldOneValuesDifferenceLength = nextFieldOneValues.length - prevFieldOneValues.length;
	//
	// 		console.log(fieldOneValuesDifferenceLength);
	//
	// 		if (fieldOneValuesDifferenceLength === 1) {
	// 			const fieldOne = nextFieldOneValues.filter(item => !prevFieldOneValues.includes(item))[0];
	//
	// 			console.log(fieldOne);
	//
	// 			const fieldOneSelect = document.getElementById("field-one-select") as HTMLSelectElement;
	//
	// 			fieldOneSelect.value = fieldOne.uuid;
	// 		}
	// 	}
	//
	// 	@Watch("fieldTwoValues")
	// 	onFieldTwoValues(nextFieldTwoValues: FieldTwo[], prevFieldTwoValues: FieldTwo[]) {
	// 		console.log(nextFieldTwoValues);
	// 		console.log(prevFieldTwoValues);
	//
	// 		const fieldTwoValuesDifferenceLength = nextFieldTwoValues.length - prevFieldTwoValues.length;
	//
	// 		console.log(fieldTwoValuesDifferenceLength);
	//
	// 		if (fieldTwoValuesDifferenceLength === 1) {
	// 			const fieldTwo = nextFieldTwoValues.filter(item => !prevFieldTwoValues.includes(item))[0];
	//
	// 			console.log(fieldTwo);
	//
	// 			const fieldTwoSelect = document.getElementById("field-two-select") as HTMLSelectElement;
	//
	// 			fieldTwoSelect.value = fieldTwo.uuid;
	// 		}
	// 	}
	//
	// 	@Watch("fieldThreeValues")
	// 	onFieldThreeValues(nextFieldThreeValues: FieldThree[], prevFieldThreeValues: FieldThree[]) {
	// 		console.log(nextFieldThreeValues);
	// 		console.log(prevFieldThreeValues);
	//
	// 		const fieldThreeValuesDifferenceLength = nextFieldThreeValues.length - prevFieldThreeValues.length;
	//
	// 		console.log(fieldThreeValuesDifferenceLength);
	//
	// 		if (fieldThreeValuesDifferenceLength === 1) {
	// 			const fieldThree = nextFieldThreeValues.filter(item => !prevFieldThreeValues.includes(item))[0];
	//
	// 			console.log(fieldThree);
	//
	// 			const fieldThreeSelect = document.getElementById("field-three-select") as HTMLSelectElement;
	//
	// 			fieldThreeSelect.value = fieldThree.uuid;
	// 		}
	// 	}

	// --------------------------------------------------
	// Lifecycle functions
	// --------------------------------------------------

	async mounted() {
		this.orders = await OrdersRepository.getAll();

		this.positionWatchId = navigator.geolocation.watchPosition((geolocationSuccess) => {
			const coords = geolocationSuccess.coords;

			if (coords.accuracy && (this.accuracy == null || coords.accuracy > this.accuracy)) {
				this.latitude = coords.latitude;
				this.longitude = coords.longitude;
				this.accuracy = coords.accuracy;
			}
		}, (error) => {
			console.error(`CODE [${error.code}] MESSAGE [${error.message}]`)
		}, {
			enableHighAccuracy: true,
			maximumAge: 15000,
		});
	}

	destroyed() {
		if (this.positionWatchId !== null) {
			navigator.geolocation.clearWatch(this.positionWatchId);
		}
	}

	// --------------------------------------------------
	// Methods
	// --------------------------------------------------

	constructionSite() {
		const constructionSitesSelect = document.getElementById("construction-sites-select") as HTMLSelectElement | null;

		if (!constructionSitesSelect) {
			return null;
		}

		const constructionSitesSelectValue = parseInt(constructionSitesSelect.value);
		const constructionSite = this.constructionSites.find(constructionSite => constructionSite.id === constructionSitesSelectValue);

		return constructionSite;
	}

	order() {
		const ordersSelect = document.getElementById("orders-select") as HTMLSelectElement | null;

		if (!ordersSelect) {
			return null;
		}

		const ordersSelectValue = parseInt(ordersSelect.value);
		const currentOrder = this.orders.find(order => order.id == ordersSelectValue);

		if (!currentOrder) {
			return null;
		}

		return currentOrder;
	}

	fieldOne() {
		const fieldOneValuesSelect = document.getElementById("field-one-select") as HTMLSelectElement | null;

		if (!fieldOneValuesSelect) {
			return null;
		}

		const fieldOne = this.fieldOneValues.find(fieldOneValue => fieldOneValue.uuid == fieldOneValuesSelect.value);

		if (!fieldOne) {
			return null;
		}

		return fieldOne;
	}

	async currentKey() {
		const orderId = this.order()?.id || "";
		const constructionSiteId = this.constructionSite()?.id || "";
		const fieldOneUuid = this.fieldOne()?.uuid || "";
		const fieldTwoUuid = this.fieldTwo()?.uuid || "";
		const fieldThreeUuid = this.fieldThree()?.uuid || "";

		const currentUser = await UsersRepository.getCurrentUser();

		if (currentUser == null) {
			throw new Error("Current user not found");
		}

		return `${orderId}${constructionSiteId}${fieldOneUuid}${fieldTwoUuid}${fieldThreeUuid}`;
	}

	fieldTwo() {
		const fieldTwoValuesSelect = document.getElementById("field-two-select") as HTMLSelectElement | null;

		if (!fieldTwoValuesSelect) {
			return null;
		}

		const fieldTwo = this.fieldTwoValues.find(fieldOneValue => fieldOneValue.uuid == fieldTwoValuesSelect.value);

		if (!fieldTwo) {
			return null;
		}

		return fieldTwo;
	}

	fieldThree() {
		const fieldThreeValuesSelect = document.getElementById("field-three-select") as HTMLSelectElement | null;

		if (!fieldThreeValuesSelect) {
			return null;
		}

		const fieldThree = this.fieldThreeValues.find(fieldThreeValue => fieldThreeValue.uuid == fieldThreeValuesSelect.value);

		if (!fieldThree) {
			return null;
		}

		return fieldThree;
	}

	checkedTags() {
		return [...document.querySelectorAll<HTMLInputElement>(".tags-checkboxes:checked")].map(tagCheckbox => tagCheckbox.value);
	}

	getNotesValue() {
		return (document.getElementById("photograph-notes") as HTMLTextAreaElement)?.value || "";
	}

	getConstructionSiteTags() {
		const constructionSitesSelect = document.getElementById("construction-sites-select") as HTMLSelectElement | null;

		if (!constructionSitesSelect || constructionSitesSelect.value == "") {
			return [];
		}

		const selectedConstructionSiteId = parseInt(constructionSitesSelect.value);
		const constructionSite = this.constructionSites.find(constructionSite => constructionSite.id == selectedConstructionSiteId);

		if (!constructionSite) {
			return [];
		}

		return constructionSite.tags;
	}

	populateConstructionSites(constructionSites: ConstructionSite[]) {
		this.deleteFieldOneValues();
		this.deleteFieldTwoValues();
		this.deleteFieldThreeValues();
		this.deletePhotographs();

		this.constructionSites = constructionSites;
	}

	showCamera(showCamera: boolean) {
		this.cameraVisible = showCamera;
	}

	populateFieldOneValues(fieldOneValues: FieldOne[]) {
		this.deleteFieldTwoValues();
		this.deleteFieldThreeValues();
		this.deletePhotographs()

		this.fieldOneValues = fieldOneValues;
	}

	async populateFieldTwoValues(fieldTwoValues: FieldTwo[]) {
		this.deleteFieldThreeValues();
		this.constructionSiteTags = this.getConstructionSiteTags();

		const currentKey = await this.currentKey();

		if (currentKey) {
			this.updatePhotographs(await PhotographsRepository.findLocal(currentKey))
		}

		this.fieldTwoValues = fieldTwoValues;
	}

	async populateFieldThreeValues(fieldThreeValues: FieldThree[]) {
		const currentKey = await this.currentKey();
		this.updatePhotographs(await PhotographsRepository.findLocal(currentKey))

		this.fieldThreeValues = fieldThreeValues;
	}

	async populateFieldFourValues(fieldThreeSelected: boolean) { //TODO future
		const currentKey = await this.currentKey();
		this.updatePhotographs(await PhotographsRepository.findLocal(currentKey))
	}

	deleteFieldOneValues() {
		this.fieldOneValues.splice(0);
	}

	deleteFieldTwoValues() {
		this.fieldTwoValues.splice(0);
	}

	deleteFieldThreeValues() {
		this.fieldThreeValues.splice(0);
	}

	deletePhotographs() {
		this.photographs.splice(0);
	}

	async pushPhototraph(photographBlob: Blob) {
		const constructionSite = this.constructionSite();
		const order = this.order();
		const fieldOne = this.fieldOne();
		const fieldTwo = this.fieldTwo();
		const fieldThree = this.fieldThree();
		const checkedTags = this.checkedTags();
		const notesValue = this.getNotesValue();

		if (!constructionSite) {
			throw new Error("Construction site not found");
		}

		if (!order) {
			throw new Error("Order not found");
		}

		if (!fieldOne) {
			throw new Error("FieldOne not found");
		}

		let uuid = "";
		let photographLocalWithSameUUIDWasFound = true;

		do {
			uuid = RandomUtils.getUUID();

			photographLocalWithSameUUIDWasFound = this.photographs.find(Photograph => Photograph.uuid == uuid) != undefined;

		} while (photographLocalWithSameUUIDWasFound);

		const photograph: Photograph = {
			id: null,
			uuid: uuid,
			blob: photographBlob,
			constructionSite: constructionSite,
			order: order,
			fieldOne: fieldOne,
			fieldTwo: fieldTwo,
			fieldThree: fieldThree,
			tags: checkedTags,
			notes: notesValue,
			coordinates: {
				latitude: this.latitude,
				longitude: this.longitude
			},
		};

		this.updatePhotographs(await PhotographsRepository.create(photograph));
	}

	updatePhotographs(photographs: Photograph[]) {
		this.photographs = photographs
	}

	pushFieldOneValues(fieldOne: FieldOne) {
		this.fieldOneValues.push(fieldOne);

		setTimeout(() => {
			const fieldOneSelect = document.getElementById("field-one-select") as HTMLSelectElement;

			fieldOneSelect.value = fieldOne.uuid

			this.currentFieldOne = fieldOne;
		}, 1000);
	}

	pushFieldTwoValues(fieldTwo: FieldTwo) {
		this.fieldTwoValues.push(fieldTwo);

		setTimeout(() => {
			const fieldTwoSelect = document.getElementById("field-two-select") as HTMLSelectElement;

			fieldTwoSelect.value = fieldTwo.uuid;

			this.currentFieldTwo = fieldTwo;
		}, 1000);
	}

	pushFieldThreeValues(fieldThree: FieldThree) {
		this.fieldThreeValues.push(fieldThree);

		setTimeout(() => {
			const fieldThreeSelect = document.getElementById("field-three-select") as HTMLSelectElement;

			fieldThreeSelect.value = fieldThree.uuid
		}, 1000);
	}
}
</script>