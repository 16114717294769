import Photograph from "@/interfaces/Photograph";
import FileUtils from "@/utils/file-utils";
import localforage from "localforage";
import axios from "axios";
import FieldOneRepository from "./field-one-repository";
import FieldTwoRepository from "./field-two-repository";
import FieldThreeRepository from "./field-three-repository";
import UsersRepository from "./users-repository";
import { User } from "@/interfaces/User";

export default class PhotographsRepository {
	static baseNamespace = "PHOTOGRAPHS";

	static async namespace() {
		const user = await UsersRepository.getCurrentUser();

		const userDbKey = user?.db_key || "";
		const userUserId = user?.id || "";

		return this.baseNamespace + "_" + userDbKey + userUserId;
	}

	static async getAll(): Promise<Map<string, Photograph[]>> {
		const namespace = await this.namespace();
		const photographsLocal: [] | undefined | null = await localforage.getItem(namespace);

		return new Map(photographsLocal);
	}

	static async create(photograph: Photograph) {
		const currentKey = this.getCurrentKey(photograph);
		const namespace = await this.namespace();

		const currentPhotographsByCurrentKey = await this.findLocal(currentKey);
		currentPhotographsByCurrentKey.push(photograph);

		const photographsLocal = (await this.getAll()).set(currentKey, currentPhotographsByCurrentKey);

		if (navigator.onLine) {
			await localforage.setItem(namespace, [...photographsLocal]); //#TODO
		} else {
			await localforage.setItem(namespace, [...photographsLocal]);
		}

		return currentPhotographsByCurrentKey;
	}

	static async syncLocalAll() {
		const photographsLocal = await this.getAll();

		const syncPhotograph: Map<string, Photograph[]> = new Map();

		for (const [key, currentPhotographs] of photographsLocal.entries()) {
			for (const currentPhotograph of currentPhotographs) {
				if (currentPhotograph.id) continue;

				const params = new FormData();

				const photographBlob = currentPhotograph.blob;
				const fileName = FileUtils.getRandomFileName(photographBlob.type)

				const photographFile = new File([currentPhotograph.blob], fileName);

				params.append("cantiere_id", currentPhotograph.constructionSite.id.toString());
				params.append("commessa_id", currentPhotograph.order.id.toString());

				const [fieldOneIdFormData, fieldTwoIdFormData, fieldThreeIdFormData] = await this.getCurrentPhotographFieldsIds(currentPhotograph);

				params.append("campo_1_id", fieldOneIdFormData);
				params.append("campo_2_id", fieldTwoIdFormData);
				params.append("campo_3_id", fieldThreeIdFormData);

				params.append("foto", photographFile);
				params.append("tags", currentPhotograph.tags.map(tag => tag.split("#")[0]).join(","));
				params.append("note", currentPhotograph?.notes || "");

				const latitude = (currentPhotograph.coordinates.latitude || "").toString();
				const longitude = (currentPhotograph.coordinates.longitude || "").toString();

				params.append("latitudine", latitude);
				params.append("longitudine", longitude);

				try {
					const response = await axios.post("/registra_photo.php", params, {
						headers: { "Content-Type": "application/x-www-form-urlencoded" }
					});

					const photographId = response.data[0]?.ID_photo ?? null;
					currentPhotograph.id = photographId;
				} catch (error) {
					console.error(error)
				}
			}

			syncPhotograph.set(key, currentPhotographs);
		}

		const namespace = await this.namespace();
		await localforage.setItem(namespace, [...syncPhotograph]);
	}

	static async findLocal(currentKey: string) {
		return (await this.getAll()).get(currentKey) || [];
	}

	static async update(photograph: Photograph) {
		const key = this.getCurrentKey(photograph);

		const currentPhotographs = await this.findLocal(key);

		const currentPhotographIndex = currentPhotographs.findIndex(currentPhotograph => currentPhotograph.uuid == photograph.uuid);
		currentPhotographs[currentPhotographIndex] = photograph;

		const photographsLocal = (await this.getAll()).set(key, currentPhotographs);

		const namespace = await this.namespace();
		await localforage.setItem(namespace, [...photographsLocal]);

		return currentPhotographs;
	}

	static async delete(photograph: Photograph) {
		const key = this.getCurrentKey(photograph);

		const currentPhotographs = await this.findLocal(key);

		const currentPhotographIndex = currentPhotographs.findIndex(currentPhotograph => currentPhotograph.uuid == photograph.uuid);
		currentPhotographs.splice(currentPhotographIndex, 1);

		const photographsLocal = (await this.getAll()).set(key, currentPhotographs);

		const namespace = await this.namespace();
		await localforage.setItem(namespace, [...photographsLocal]);

		return currentPhotographs;
	}

	static getCurrentKey(photograph: Photograph) {
		const photographOrderId = photograph.order.id;
		const photographConstructionId = photograph.constructionSite.id;
		const photographFieldOneUuid = photograph.fieldOne.uuid;
		const photographFieldTwoUuid = photograph.fieldTwo?.uuid || "";
		const photographFieldThreeUuid = photograph.fieldThree?.uuid || "";

		return `${photographOrderId}${photographConstructionId}${photographFieldOneUuid}${photographFieldTwoUuid}${photographFieldThreeUuid}`;
	}

	static async deleteAll() {
		const namespace = await this.namespace();

		await localforage.setItem(namespace, []);
	}

	static async getCurrentPhotographFieldsIds(currentPhotograph: Photograph) {
		const fieldOneIdFormData = await this.getCurrentPhotographFieldOneId(currentPhotograph);
		const fieldTwoIdFormData = await this.getCurrentPhotographFieldTwoId(currentPhotograph);
		const fieldThreeIdFormData = await this.getCurrentPhotographFieldThreeId(currentPhotograph);

		return [
			(fieldOneIdFormData ?? "").toString(),
			(fieldTwoIdFormData ?? "").toString(),
			(fieldThreeIdFormData ?? "").toString()
		];
	}

	private static async getCurrentPhotographFieldOneId(currentPhotograph: Photograph) {
		const currentPhotographFieldOneId = currentPhotograph.fieldOne?.id || null;

		let fieldOneId = currentPhotographFieldOneId
			? currentPhotographFieldOneId
			: (await FieldOneRepository.findByUuid(currentPhotograph.fieldOne.uuid))?.id || null;

		return fieldOneId;
	}

	private static async getCurrentPhotographFieldTwoId(currentPhotograph: Photograph) {
		const currentPhotographFieldTwoId = currentPhotograph.fieldTwo?.id || null;
		let fielTwoId: number | null = null;

		if (currentPhotograph.fieldTwo) {
			fielTwoId = currentPhotographFieldTwoId
				? currentPhotographFieldTwoId
				: (await FieldTwoRepository.findByUuid(currentPhotograph.fieldTwo.uuid))?.id || null;
		}

		return fielTwoId;
	}

	private static async getCurrentPhotographFieldThreeId(currentPhotograph: Photograph) {
		const currentPhotographFieldThreeId = currentPhotograph.fieldThree?.id || null;

		let fieldThreeId: number | null = null;

		if (currentPhotograph.fieldThree) {
			fieldThreeId = currentPhotographFieldThreeId
				? currentPhotographFieldThreeId
				: (await FieldThreeRepository.findByUuid(currentPhotograph.fieldThree.uuid))?.id || null;
		}

		return fieldThreeId;
	}
}