import { render, staticRenderFns } from "./PhotographsGrid.vue?vue&type=template&id=3959b102"
import script from "./PhotographsGrid.vue?vue&type=script&lang=ts"
export * from "./PhotographsGrid.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports