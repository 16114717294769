import FieldTwo from "@/interfaces/FieldTwo";
import Field2 from "@/interfaces/FieldTwo";
import RandomUtils from "@/utils/random-utils";
import axios from "axios";
import localforage from "localforage";
import FieldOneRepository from "./field-one-repository";
import FieldThreeRepository from "./field-three-repository";

type RawFieldTwo = {
	ID: string,
	ID_campo1: string,
	campo2: string,
}

export default class FieldTwoRepository {
	static namespace = "FIELD_TWO_VALUES";

	static async getAll(): Promise<Map<string, FieldTwo[]>> {
		const fieldTwoValues: [] | null | undefined = await localforage.getItem(this.namespace);

		return new Map(fieldTwoValues);
	}

	static async syncAll() {
		const fieldTwoValuesMap = await this.getAll();

		const syncFieldTwoValusMap: Map<string, FieldTwo[]> = new Map();

		for (const [key, fieldTwoValues] of fieldTwoValuesMap.entries()) {
			for (const fieldTwo of fieldTwoValues) {
				if (fieldTwo.id) continue;

				const params = new FormData();

				try {
					const fieldOneId = fieldTwo.field_one_id ? fieldTwo.field_one_id.toString() : "";

					params.append("campo_1_id", fieldOneId);
					params.append("campo_2_nome", fieldTwo.name);

					const response = await axios.post("registra_campo2.php", params, {
						headers: { "Content-Type": "application/x-www-form-urlencoded" }
					})

					const fieldTwoId: number | null = response.data[0]?.campo_2_id || null;
					fieldTwo.id = fieldTwoId;

					let fieldThreeValues = await FieldThreeRepository.find(fieldTwo.uuid);

					if (fieldThreeValues.length > 0) {
						fieldThreeValues = fieldThreeValues.map(fieldThree => {
							return {
								id: fieldThree.id,
								name: fieldThree.name,
								uuid: fieldThree.uuid,
								field_two_id: fieldTwoId,
							}
						});

						await FieldThreeRepository.update(fieldTwo.uuid, fieldThreeValues);
					}
				} catch (error) {
					console.error(error)
				}
			}

			syncFieldTwoValusMap.set(key, fieldTwoValues);
		}

		await localforage.setItem(this.namespace, [...syncFieldTwoValusMap]);
	}

	static async replaceAllFromRemote() {
		const response = await axios.get("/campo2.php");

		const data: RawFieldTwo[] = response.data;

		const fieldTwoMap: Map<string, FieldTwo[]> = new Map();
		const currentFieldTwoMap = await this.getAll();

		for (const rawFieldTwo of data) {
			const fieldOneId = parseInt(rawFieldTwo.ID_campo1);
			const fieldOne = await FieldOneRepository.findById(fieldOneId)

			if (!fieldOne) {
				console.error(`Dont find ${fieldOneId} in FieldTwoRepository.replaceAllFromRemote`)
				continue;
			}

			const currentFieldTwoValues = currentFieldTwoMap.get(fieldOne.uuid) || [];
			const fieldTwoValues = fieldTwoMap.get(fieldOne.uuid) || [];

			const fieldTwoId = parseInt(rawFieldTwo.ID);
			const currentFieldTwo = currentFieldTwoValues.find(currentFieldTwo => currentFieldTwo.id == fieldTwoId)

			fieldTwoValues.push({
				uuid: currentFieldTwo ? currentFieldTwo.uuid : RandomUtils.getUUID(),
				id: fieldTwoId,
				name: rawFieldTwo.campo2,
				field_one_id: fieldOneId,
			});

			fieldTwoMap.set(fieldOne.uuid, fieldTwoValues);
		}

		await localforage.setItem(this.namespace, [...fieldTwoMap]);
	}

	static async find(fieldOneUuid: string): Promise<Field2[]> {
		return (await this.getAll()).get(fieldOneUuid) || [];
	}

	static async findByUuid(fieldTwoUuid: string) {
		const fieldTwoMap = await this.getAll();

		for (const fieldTwoValues of fieldTwoMap.values()) {
			const fieldTwo = fieldTwoValues.find(fieldTwo => fieldTwo.uuid == fieldTwoUuid);

			if (fieldTwo) {
				return fieldTwo;
			}
		}

		return null;
	}

	static async findById(fieldTwoId: number) {
		const fieldTwoMap = await this.getAll();

		for (const fieldTwoValues of fieldTwoMap.values()) {
			const fieldTwo = fieldTwoValues.find(fieldTwo => fieldTwo.id == fieldTwoId);

			if (fieldTwo) {
				return fieldTwo;
			}
		}

		return null;
	}

	static async deleteAll() {
		await localforage.setItem(this.namespace, []);
	}

	static async create(fieldOneUuid: string, fieldTwoValue: string) {
		const fieldTwoValues = await this.find(fieldOneUuid);

		const fieldOne = await FieldOneRepository.findByUuid(fieldOneUuid);

		if (!fieldOne) {
			return null;
		}

		const fieldTwo: FieldTwo = {
			uuid: RandomUtils.getUUID(),
			id: null,
			name: fieldTwoValue,
			field_one_id: fieldOne.id,
		};

		fieldTwoValues.push(fieldTwo);

		const fieldTwoMap = (await this.getAll()).set(fieldOneUuid, fieldTwoValues)
		await localforage.setItem(this.namespace, [...fieldTwoMap])

		return fieldTwo;
	}

	static async update(fieldOneUuid: string, fieldTwoValues: FieldTwo[]) {
		const fieldsTwoMap = await this.getAll();

		fieldsTwoMap.set(fieldOneUuid, fieldTwoValues);

		await localforage.setItem(this.namespace, [...fieldsTwoMap]);
	}
}