import FieldOne from "@/interfaces/FieldOne";
import RandomUtils from "@/utils/random-utils";
import axios from "axios";
import localforage from "localforage";
import FieldTwoRepository from "./field-two-repository";

type RawFieldOne = {
	ID: string,
	ID_cantiere: string,
	campo1: string
}

export default class FieldOneRepository {
	static namespace = "FIELD_ONE_VALUES";

	static async getAll(): Promise<Map<number, FieldOne[]>> {
		const fieldOneValues: [] | null | undefined = await localforage.getItem(this.namespace);

		return new Map(fieldOneValues);
	}

	static async syncAll() {
		const fieldOneValuesMap = await this.getAll();

		const syncFieldOneValusMap: Map<number, FieldOne[]> = new Map();

		for (const [key, fieldOneValues] of fieldOneValuesMap.entries()) {
			for (const fieldOne of fieldOneValues) {
				if (fieldOne.id) continue;

				const params = new FormData();

				params.append("campo_1_nome", fieldOne.name);
				params.append("cantiere_id", fieldOne.construction_site_id.toString());

				try {
					const response = await axios.post("registra_campo1.php", params, {
						headers: { "Content-Type": "application/x-www-form-urlencoded" }
					})

					const fieldOneId = response.data[0]?.campo_1_id || null;
					fieldOne.id = fieldOneId;

					let fieldTwoValues = await FieldTwoRepository.find(fieldOne.uuid);

					if (fieldTwoValues.length > 0) {
						fieldTwoValues = fieldTwoValues.map(fieldTwo => {
							return {
								uuid: fieldTwo.uuid,
								id: fieldTwo.id,
								name: fieldTwo.name,
								field_one_id: fieldOneId,
							}
						});

						await FieldTwoRepository.update(fieldOne.uuid, fieldTwoValues);
					}
				} catch (error) {
					console.error(error)
				}
			}

			syncFieldOneValusMap.set(key, fieldOneValues);
		}

		await localforage.setItem(this.namespace, [...syncFieldOneValusMap]);
	}

	static async replaceAllFromRemote() {
		const response = await axios.get("/campo1.php");

		const data: RawFieldOne[] = response.data;

		const fieldOneMap: Map<number, FieldOne[]> = new Map();
		const currentFieldOneMap = await this.getAll();

		for (const rawFieldOne of data) {
			const constructionSiteId = parseInt(rawFieldOne.ID_cantiere);

			const fieldOneValues = fieldOneMap.get(constructionSiteId) || [];
			const currentFieldOneValues = currentFieldOneMap.get(constructionSiteId) || [];

			const fieldOneId = parseInt(rawFieldOne.ID);
			const currentFieldOne = currentFieldOneValues.find(currentFieldOne => currentFieldOne.id == fieldOneId);

			fieldOneValues.push({
				uuid: currentFieldOne ? currentFieldOne.uuid : RandomUtils.getUUID(),
				id: fieldOneId,
				name: rawFieldOne.campo1,
				construction_site_id: constructionSiteId,
			});

			fieldOneMap.set(constructionSiteId, fieldOneValues);
		}

		localforage.setItem(this.namespace, [...fieldOneMap]);
	}


	static async find(constructionSiteId: number) {
		return (await this.getAll()).get(constructionSiteId) || [];
	}

	static async findById(fieldOneid: number) {
		const fieldOneMap = await this.getAll();

		for (const fieldOneValues of fieldOneMap.values()) {
			const fieldOne = fieldOneValues.find(fieldOne => fieldOne.id == fieldOneid);

			if (fieldOne) {
				return fieldOne;
			}
		}

		return null;
	}

	static async findByUuid(fieldOneUuid: string) {
		const fieldOneMap = await this.getAll();

		for (const fieldOneValues of fieldOneMap.values()) {
			const fieldOne = fieldOneValues.find(fieldOne => fieldOne.uuid == fieldOneUuid);

			if (fieldOne) {
				return fieldOne;
			}
		}

		return null;
	}

	static async deleteAll() {
		await localforage.setItem(this.namespace, []);
	}

	static async create(constructionSiteId: number, fieldOneValue: string) {
		const fieldOneValues = await this.find(constructionSiteId);

		const fieldOne: FieldOne = {
			uuid: RandomUtils.getUUID(),
			id: null,
			name: fieldOneValue,
			construction_site_id: constructionSiteId,
		};

		fieldOneValues.push(fieldOne);

		const fieldOneMap = (await this.getAll()).set(constructionSiteId, fieldOneValues)
		await localforage.setItem(this.namespace, [...fieldOneMap])

		return fieldOne;
	}
}
