<template>
<div class="container mb-3">
	<div class="row g-3">
		<div class="col-4 border rounded p-2" v-for="(photograph, index) in currentPhotographs" :key="index">
			<img :src="FileUtils.getObjectURL(photograph.blob)" class="img-fluid" @click="openModal(photograph)">
			<div class="row p-2 d-flex justify-content-between">
				<div class="col-sm-6" @click="openModal(photograph)">
					{{ photograph.tags.map(tag => tag.split("#")[0]).join(", ") }}
				</div>

				<div class="text-end col-sm-6">
					<button class="btn btn-sm btn-danger" type="button" @click="deletePhotograph(photograph)">
						<i class="fa fa-trash"></i>
					</button>
				</div>
			</div>
		</div>
	</div>

	<b-modal id="photographs-tags-modal" header-close-variant=" btn btn-light" cancel-title="Chiudi"
		@ok="updateSelectedPhotograph">
		<template #modal-title>
			<h5 class="modal-title">Tags</h5>
		</template>

		<div class="mb-3">
			<label for="selected-photograph-construction-site-notes" class="form-label">Note</label>
			<textarea id="selected-photograph-construction-site-notes"
				name="selected-photograph-construction-site-notes" class="form-control"
				:value="selectedPhotograph?.notes || ''">
			</textarea>
		</div>

		<div class="form-check" v-for="constructionSiteTag in selectedPhotograph?.constructionSite.tags"
			:key="constructionSiteTag">
			<input type="checkbox" class="form-check-input selected-photograph-construction-site-tags"
				:value="constructionSiteTag" :id="constructionSiteTag"
				:checked="selectedPhotograph?.tags.includes(constructionSiteTag)">
			<label class="form-check-label" type="checkbox" :for="constructionSiteTag">
				{{ getConstructionTagValue(constructionSiteTag) }}
			</label>
		</div>
	</b-modal>
</div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import FileUtils from "@/utils/file-utils"
import Photograph from '@/interfaces/Photograph'
import PhotographsRepository from '@/repositories/photographs-repository'

@Component
export default class PhotographsGrid extends Vue {
	FileUtils: typeof FileUtils = FileUtils;
	selectedPhotograph: Photograph | null = null;
	visibleModal = false;

	@Prop(Array) currentPhotographs!: Photograph[];

	checkedTags() {
		return [...document.querySelectorAll<HTMLInputElement>(".selected-photograph-construction-site-tags:checked")].map(tagCheckbox => tagCheckbox.value);
	}

	getNotesValue() {
		return (document.getElementById("selected-photograph-construction-site-notes") as HTMLTextAreaElement | null)?.value || "";
	}

	openModal(photograph: Photograph) {
		this.selectedPhotograph = photograph;

		this.$bvModal.show('photographs-tags-modal')
	}

	getConstructionTagValue(tag: string) {
		return tag.split("#")[1] || "";
	}

	getConstructionTagDataShortName(tag: string) {
		return tag.split("#")[0] || "";
	}

	updateSelectedPhotograph() {
		if (!this.selectedPhotograph) {
			return;
		}

		this.selectedPhotograph.tags = this.checkedTags();
		this.selectedPhotograph.notes = this.getNotesValue();

		PhotographsRepository.update(this.selectedPhotograph);
	}

	async deletePhotograph(photograph: Photograph) {
		const photographs = await PhotographsRepository.delete(photograph);

		this.$emit("updatePhotographs", photographs);
	}
}
</script>
