<template>
<div class="input-group mb-3">
	<span class="input-group-text">{{ label }}</span>
	<select name="order_id" class="form-control" id="orders-select" @input="populateConstructionSites">
		<option value="">Seleziona</option>
		<option v-for="order in orders" :value="order.id" :key="order.id">
			{{ order.name }}
		</option>
	</select>
</div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import settings from '@/settings';
import Order from '@/interfaces/Order';
import ConstructionSitesRepository from '@/repositories/construction-sites-repository';

@Component
export default class OrdersSelect extends Vue {
	label = settings.fieldOrderLabel;
	@Prop(Array) orders!: Order[];

	async populateConstructionSites() {
		const ordersSelect = document.getElementById("orders-select") as HTMLSelectElement;

		const ordersSelectValue = parseInt(ordersSelect.value);

		const constructions = await ConstructionSitesRepository.find(ordersSelectValue);

		this.$emit("populateConstructionSites", constructions);
	}
}
</script>