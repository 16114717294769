import localForage from 'localforage'
import { NavigationGuardNext, Route } from 'vue-router';

export default async function (to: Route, from: Route, next: NavigationGuardNext) {
	const user = await localForage.getItem("USER")

	if (user) {
		return next(from.path)
	}

	return next()
}