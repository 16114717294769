<template>
	<div id="app" :class="{ 'has-available-updates': hasAvailableUpdates }">
		<MainHeader></MainHeader>

		<div class="container" id="panel-updates-available" v-if="hasAvailableUpdates">
			<div class="card mb-3">
				<div class="card-body">
					<h4 class="text-center p-4">
						Nuova versione disponibile.
					</h4>

					<button class="btn btn-success w-100" type="button" @click="performUpdate()">
						Aggiorna
					</button>
				</div>
			</div>
		</div>
		<router-view v-else />
	</div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class PhotosCreateView extends Vue {
	isRefreshingServiceWorker = false;
	isUpdating = false;
	hasAvailableUpdates = false;
	serviceWorkerRegistration: ServiceWorkerRegistration | null = null;


	created() {
		document.addEventListener("serviceWorkerUpdated", this.showUpdatesAvailableUI, { once: true })

		navigator.serviceWorker.addEventListener("controllerchange", () => {
			if (this.isRefreshingServiceWorker) {
				return
			}

			this.isRefreshingServiceWorker = true

			window.location.reload()
		})
	}


	showUpdatesAvailableUI(event: CustomEventInit<ServiceWorkerRegistration>) {
		this.serviceWorkerRegistration = event.detail || null;
		this.hasAvailableUpdates = true
	}

	performUpdate() {
		this.hasAvailableUpdates = false

		if (!this.serviceWorkerRegistration || !this.serviceWorkerRegistration.waiting) {
			return
		}

		this.isUpdating = true
		this.serviceWorkerRegistration.waiting.postMessage({ type: 'SKIP_WAITING' })
	}
}
</script>
