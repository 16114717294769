<template>
<div class="mt-3">
	<div class="mb-3">
		<button type="button" class="btn btn-primary btn-sm" @click="openModal">
			Crea {{ label }}
		</button>
	</div>

	<b-modal id="create-field-two-modal" header-close-variant=" btn btn-light" cancel-title="Chiudi" @ok="create">
		<template #modal-title>
			<h5 class="modal-title">Crea {{ label }}</h5>
		</template>

		<div class="input-group mb-3">
			<span class="input-group-text">{{ label }}</span>
			<input type="text" class="form-control" id="field-two-input">
		</div>
	</b-modal>
</div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import settings from '@/settings';
import FieldOne from '@/interfaces/FieldOne';
import FieldTwoRepository from '@/repositories/field-two-repository';

@Component
export default class CreateFieldTwo extends Vue {
	label = settings.fieldTwoLabel;
	fieldTwoText: string = "";
	@Prop() fieldOne!: FieldOne;

	openModal() {
		this.$bvModal.show('create-field-two-modal')
	}

	async create() {
		const fieldTwoInput = document.getElementById("field-two-input") as HTMLInputElement
		const fieldTwoValue = fieldTwoInput.value.trim();

		if (!fieldTwoValue) {
			alert("È obbligatorio inserire un valore nell'input");
			return;
		}

		const fieldTwo = await FieldTwoRepository.create(this.fieldOne.uuid, fieldTwoValue);

		this.$emit("createFieldTwo", fieldTwo);
	}
}
</script>