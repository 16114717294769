<template>
<div class="input-group mb-3">
	<span class="input-group-text">{{ label }}</span>
	<select name="field_two_id" class="form-control" id="field-two-select" @input="populateFieldThreeValues()">
		<option value="">Seleziona</option>
		<option v-for="fieldTwo in fieldTwoValues" :value="fieldTwo.uuid" :key="fieldTwo.uuid"
			:data-name="fieldTwo.name">
			{{ fieldTwo.name }}
		</option>
	</select>
	<button class="btn btn-success" type="button" @click="openModal">
		<i class="fa fa-plus"></i>
	</button>

	<b-modal id="create-field-two-modal" header-close-variant=" btn btn-light" cancel-title="Chiudi" @ok="create">
		<template #modal-title>
			<h5 class="modal-title">Crea {{ label }}</h5>
		</template>

		<div class="input-group mb-3">
			<span class="input-group-text">{{ label }}</span>
			<input type="text" class="form-control" id="field-two-input">
		</div>
	</b-modal>
</div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import FieldTwo from '@/interfaces/FieldTwo'
import FieldThreeRepository from '@/repositories/field-three-repository';
import settings from '@/settings';
import FieldTwoRepository from '@/repositories/field-two-repository';
import FieldOne from '@/interfaces/FieldOne';

@Component
export default class FieldTwoSelect extends Vue {
	label = settings.fieldTwoLabel;
	@Prop(Array) fieldTwoValues!: FieldTwo[];
	@Prop(Object) fieldOne!: FieldOne;

	async populateFieldThreeValues() {
		const fieldTwoSelect = document.getElementById("field-two-select") as HTMLSelectElement;

		const fieldTwoSelectValue = fieldTwoSelect.value;

		const fieldThreeValues = await FieldThreeRepository.find(fieldTwoSelectValue);

		return this.$emit("populateFieldThreeValues", fieldThreeValues);
	}

	async create() {
		const fieldTwoInput = document.getElementById("field-two-input") as HTMLInputElement
		const fieldTwoValue = fieldTwoInput.value.trim();

		if (!fieldTwoValue) {
			alert("È obbligatorio inserire un valore nell'input");
			return;
		}

		const fieldTwo = await FieldTwoRepository.create(this.fieldOne.uuid, fieldTwoValue);

		this.$emit("createFieldTwo", fieldTwo);
	}

	openModal() {
		this.$bvModal.show('create-field-two-modal')
	}
}
</script>