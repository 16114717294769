import localForage from 'localforage'
import { Route, NavigationGuardNext } from 'vue-router';

export default async function (to: Route, from: Route, next: NavigationGuardNext) {
	const user = await localForage.getItem("USER")

	if (user) {
		return next()
	}

	return next({ name: 'auth.login' })
}